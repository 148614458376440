import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"

const NotFoundPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Not Found</title>
        <meta name="description" content="Not Found" />
      </Helmet>
      <div className="container pl-40 mx-auto mt-5">
        <h1>Page Not Found <span role="img" aria-label="sad">😔</span></h1>
      </div>
    </Layout>
  )
}

export default NotFoundPage
